import React, { useState, useCallback, useContext } from "react";
import { Hex, Layout } from "../hexgrid/hexlib";
import { CellData, State, Terrain } from "../store";
import { useMappedState, useDispatch } from "redux-react-hook";
import { mapContext } from "./map-context";
import { buildFarm } from "../store/actions";

interface Props {
  hex: Hex;
}

export const Tile: React.SFC<Props> = (props) => {
  const { hex } = props;
  const { layout, points } = useContext(mapContext);

  const mapState = useCallback((state: State) => {
    const cell = state.cells.get(hex);
    if (!cell) {
      console.log("CELL NOT FOUND", { hex, keus: Array.from(state.cells.keys()) });
      return { terrain: Terrain.water };
    }
    return {
      ... cell
    };
  }, [hex]);

  const cell = useMappedState(mapState) || Terrain.water;
  const dispatch = useDispatch();

  const pixel = layout.hexToPixel(hex);
  const fill = getFill(cell.terrain);

  return (
    <>
      <g id={hex.toString()} transform={`translate(${pixel.x},${pixel.y})`} onClick={_ => {
        if (cell.farmId || cell.cityId) {
          return;
        }
        dispatch(buildFarm(hex));
      }}>
        <polygon points={points} fill={fill} strokeWidth={0} stroke="hsl(0, 0%, 70%)" />
        {cell.cityId && <City cityId={cell.cityId} />}
        {cell.farmId && <Farm id={cell.farmId} />}
      </g>
    </>
  )
}

interface CityProps {
  cityId: number;
}

const City: React.SFC<CityProps> = (props) => {
  const { layout, points } = useContext(mapContext);
  const mapState = useCallback((state: State) => {
    const city = state.cities.get(props.cityId);
    if (!city) {
      console.log("CITY NOT FOUND", props);
      return;
    }
    return {
      ...city
    };
  }, [props.cityId]);

  const city = useMappedState(mapState);

  if (!city) {
    return null;
  }

  return (
    <g>
      <polygon points={points} fill="yellow" strokeWidth={0} stroke="hsl(0, 0%, 70%)" />
      <text textAnchor="middle" fontSize={10}>
        <tspan>
          Pop: {city.population}
        </tspan>
        <tspan x={0} dy="1em">
          Food: {city.food.toPrecision()}
        </tspan>
        <tspan x={0} dy="1em">
          Growth: {city.growth.toPrecision(1)}
        </tspan>
      </text>
    </g>
  )
}

interface FarmProps {
  id: number;
}

const Farm: React.SFC<FarmProps> = (props) => {
  const { layout, points } = useContext(mapContext);
  const mapState = useCallback((state: State) => {
    const farm = state.farms.get(props.id);
    if (!farm) {
      console.log("FARM NOT FOUND", props);
      return;
    }
    return {
      ...farm
    };
  }, [props.id]);

  const farm = useMappedState(mapState);

  if (!farm) {
    return null;
  }

  return (
    <g>
      <polygon points={points} fill="green" strokeWidth={0} stroke="hsl(0, 0%, 70%)" />
      <text textAnchor="middle" fontSize={10}>
        <tspan>
          Harvest %: {farm.progress.toPrecision(1)}
        </tspan>
      </text>
    </g>
  )
}


function getFill(terrain: Terrain) {
  // const v = props.object || props.terrain;
  const v = terrain;
  switch (v) {
    // case "farm":
    //   return `url(#object-farm)`;
    // case "colony":
    //   return `url(#object-colony)`;
    case Terrain.dessert:
      return `url(#terrain-desert)`;
    case Terrain.earth:
      return `url(#terrain-grass)`;
    case Terrain.mountain:
      return `url(#terrain-mountain)`;
    case Terrain.water:
      return `url(#terrain-water)`;
  }
  console.warn("unknown fill type:", v);
  return "red";
}

export default Tile;
