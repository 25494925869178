import { createStore, Dispatch, bindActionCreators } from "redux";
import { reducer, Actions } from "./reducers";
import { tick, generateWorld, changeRandomTile } from "./actions";
export * from "./state";

import * as actionCreators from "./actions";
import { EventEmitter } from "../events";
import { Loop } from "../loop";

export const actions = actionCreators;

const devTools = (window as any).__REDUX_DEVTOOLS_EXTENSION__;

export const store = createStore(reducer, devTools && devTools());

export type Dispatch = Dispatch<Actions>;


export const loop = new Loop(update, render, { tickLength: 500});


function update() {
  store.dispatch(tick());
}

function render() {
}

store.dispatch(generateWorld(3));

loop.start();