import { loop } from "../store";
import { useEffect, useState } from "react";

export function useGame(): [boolean, { start(): void, stop(): void }] {
  const [isRunning, setIsRunning] = useState(() => loop.isRunning);
  
  useEffect(() => {
    return loop.changed.on(() => {
      setIsRunning(loop.isRunning);
    })
  }, []);

  return [isRunning, {
    start: loop.start,
    stop: loop.stop,
  }];
}