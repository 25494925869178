import { ActionType, getType } from "typesafe-actions";
import * as actions from "./actions";
import { State, Terrain, CellData, City, Farm } from "./state";
import { hexagon } from "../hexgrid/generators";
import { Hex } from "../hexgrid/hexlib";
import { max } from "./util";
import Big from "big.js";

export type Actions = ActionType<typeof actions>;

const initialState: State = {
  tickNumber: 0,
  currentId: 0,
  cells: new Map([[new Hex(0, 0, 0), { terrain: Terrain.dessert }]]),
  cities: new Map(),
  farms: new Map(),
};

let currentId = 0;

function getNextId() {
  return ++currentId;
}

export const reducer = (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case getType(actions.tick):
      for (const [id, city] of state.cities) {
        if (city.food.gt(0)) {
          city.food = max(city.food.minus(city.population), new Big(0));
          if (city.growth < 0) {
            city.growth = 0;
          }
          city.growth = city.growth + 0.2;
        } else {
          if (city.growth > 0) {
            city.growth = 0;
          }
          city.growth = city.growth - 0.2;
        }

        if (city.growth >= 1) {
          city.growth = 0
          city.population = city.population + 1;
        } else if (city.growth <= -1) {
          city.population = city.population - 1;
          city.growth = 0;
        }
      }
      
      const mainCity = state.cities.values().next().value;

      for (const [id, farm] of state.farms) {
        farm.progress = farm.progress.add(farm.progressPerTick);
        if (farm.progress.gt(1)) {
          console.log(`Harvested ${farm.foodPerHarvest} food from ${farm.id}`);
          mainCity.food = mainCity.food.add(farm.foodPerHarvest);
          farm.progress = new Big(0);
        }
      }
      return { ...state, tickNumber: ++state.tickNumber };
    case getType(actions.generateWorld):
      const cells = new Map<Hex, CellData>();
      for (const hex of hexagon(action.payload.size)) {
        // cells.set(hex, { terrain: getRandomTerrain() });
        cells.set(hex, { terrain: Terrain.earth });
      }

      const hexPool = shuffle(Array.from(cells.keys()));
      
      const city = makeCity(hexPool[0]);
      cells.get(city.hex)!.cityId = city.id;
      const cities = new Map<number, City>([[city.id, city]]);

      const s = {
        ...state,
        tickNumber: 0,
        cells,
        cities,
      }
      console.log(s);
      return s;
    case getType(actions.changeRandomTile):
      const hexes = Array.from(state.cells.keys());
      const hex = hexes[Math.floor(Math.random() * hexes.length)];
      const data = state.cells.get(hex);
      if (!data) {
        throw new Error(`hex data not found: ${hex}`);
      }
      state.cells.set(hex, { ...data, terrain: getRandomTerrain() });
      return state;
    case getType(actions.buildFarm):
      const newFarm = makeFarm(action.payload.hex);
      state.cells.get(newFarm.hex)!.farmId = newFarm.id;
      state.farms.set(newFarm.id, newFarm);
      return state;
  }
  return state;
}

function getRandomTerrain() {
  const terrains = [Terrain.dessert, Terrain.earth, Terrain.mountain, Terrain.water];
  return terrains[Math.floor(Math.random() * terrains.length)];
}

function getRandom<T>(items: T[]): T | null {
  if (items.length === 0) {
    return null;
  }
  return items[Math.floor(Math.random() * items.length)];
}


function makeFarm(hex: Hex): Farm {
  return {
    id: getNextId(),
    hex,
    foodPerHarvest: new Big(20),
    progressPerTick: new Big(0.1),
    progress: new Big(0),
  };
}

function makeCity(hex: Hex): City {
  return {
    id: getNextId(),
    hex,
    population: 1,
    food: new Big(100),
    growth: 0,
  };
}

/**
 * Returns a random integer between min (inclusive) and max (inclusive).
 * The value is no lower than min (or the next integer greater than min
 * if min isn't an integer) and no greater than max (or the next integer
 * lower than max if max isn't an integer).
 * Using Math.round() will give you a non-uniform distribution!
 */
function getRandomInt(min: number, max: number): number {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function shuffle<T>(array: Array<T>): Array<T> {
  let counter = array.length;

  // While there are elements in the array
  while (counter > 0) {
    // Pick a random index
    let index = Math.floor(Math.random() * counter);

    // Decrease counter by 1
    counter--;

    // And swap the last element with it
    let temp = array[counter];
    array[counter] = array[index];
    array[index] = temp;
  }

  return array;
}