import React, { useState, useCallback, useContext } from "react";
import { Hex, Layout, Point } from "../hexgrid/hexlib";
import { CellData, State, Terrain } from "../store";
import { useDispatch, useMappedState } from 'redux-react-hook';
import Tile from "./Tile";
import { Assets } from "../Assets";
import { mapContext } from "./map-context";
// import LocationTile from "./LocationTile";

// interface Props {
//   sizeX?: number;
//   sizeY?: number;
// }

// const padding = 30;

// const layout = new Layout(Layout.pointy, new Point(52, 52), new Point(0, 0));
// const points = layout.polygonCorners(new Hex(0, 0, 0)).map(p => `${p.x},${p.y}`).join(" ");

export const TileMap: React.SFC<{}> = (props) => {
  const mapState = useCallback((state: State) => ({
    hexes: Array.from(state.cells.keys()),
  }), []);

  const { hexes } = useMappedState(mapState);
  const { layout, padding } = useContext(mapContext);

  const dimensions = layout.dimensions(hexes);
  const x = dimensions.x - padding;
  const y = dimensions.y - padding;
  const height = dimensions.height + padding * 2;
  const width = dimensions.width + padding * 2;
  const viewBox = `${x} ${y} ${width} ${height}`;
  
  return (
    <svg height={height} width={width} viewBox={viewBox}>
      <Assets height={layout.hexSize().y} width={layout.hexSize().x} />
      <g>
        {hexes.map(h => <Tile hex={h} key={h.toString()} />)}
      </g>
      {/* <g>
        {locations.map(h => <LocationTile hex={h.hex} layout={layout} points={points} key={h.id} location={h} />)}
      </g> */}
    </svg>
  );
}

TileMap.defaultProps = {
  sizeX: 50,
  sizeY: 50,
};

export default TileMap;

// const ConnectedTileMap = connect((state: State) => {
//   const hexes = Array.from(state.cells.keys());
//   const locations = Array.from(state.locations.values());
//   return { hexes, locations };
// })(TileMap);

// export default ConnectedTileMap;
