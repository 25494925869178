import { Hex, Point } from "../hexgrid/hexlib";
import Big from "big.js";

export enum Terrain {
  earth,
  water,
  mountain,
  dessert
};

// export enum Objects {
//   city,
//   farm,
// };

export interface CellData {
  terrain: Terrain;
  cityId?: number;
  farmId?: number;
};

// export interface Location {
//   id: number;
//   hex: Hex;
//   type: string;
// }

// export interface Farm extends Location {
//   type: "farm";
//   foodPerTick: number;
//   costPerTick: number;
// }

// export interface Colony extends Location {
//   type: "colony";
//   foodPerTick: number;
// }

export interface City {
  id: number;
  hex: Hex;
  population: number;
  food: Big;
  growth: number;
}

export interface Farm {
  id: number;
  hex: Hex;
  foodPerHarvest: Big;
  progressPerTick: Big;
  progress: Big;
}


export interface State {
  tickNumber: number;
  currentId: number;
  cells: Map<Hex, CellData>;
  cities: Map<number, City>;
  farms: Map<number, Farm>;
}

