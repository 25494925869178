import { EventEmitter, EventSource } from "./events";

export type DeltaTime = DOMHighResTimeStamp;
export type GameTime = DOMHighResTimeStamp;

export type UpdateFn = (dt: DeltaTime, gt: GameTime) => void;
export type RenderFn = (dt: DeltaTime, gt: GameTime) => void;

export class Loop {
  private readonly tickLength: number;
  private readonly updateFn: UpdateFn;
  private readonly renderFn: RenderFn;
  private startTime: GameTime = 0;
  private lastTick: GameTime = 0;
  private lastRender: GameTime = 0;

  private _isRunning = false;
  private _requestStop = false;

  private _emitter = new EventEmitter();

  constructor(updateFn: UpdateFn, renderFn: RenderFn, options: { tickLength?: number } = {}) {
    this.updateFn = updateFn;
    this.renderFn = renderFn;
    this.tickLength = options.tickLength || 1000;
    this.maxTicks = Math.floor(1000 / this.tickLength);

    this.tick = this.tick.bind(this);
    this.start = this.start.bind(this);
    this.stop = this.stop.bind(this);
  }

  public get changed(): EventSource<{}> {
    return this._emitter;
  }

  public get isRunning() {
    return this._isRunning;
  }

  public start() {
    console.info("Starting...");
    this.startTime = performance.now();
    this.lastTick = performance.now();
    this.lastRender = this.lastTick;

    console.log("this", { this: this, tick: this.tick});
    // this.setInitialState();
    this._isRunning = true;
    this._emitter.emit({});
    this.tick(performance.now());
  }

  public stop() {
    if (!this.isRunning) {
      return;
    }
    console.info("Stopping...");
    this._requestStop = true;
  }

  // public runTime() {
  //   // return this.runTime.
  // }

  private deltaTime: GameTime = 0;
  private last: GameTime = 0;

  private maxTicks: number;

  private tick(gameTime: DOMHighResTimeStamp) {
    // console.log("tick", gameTime);

    // gameTime = gameTime / 1000;

    var nextTick = this.lastTick + this.tickLength;

    // If dt < nextTick then 0 ticks need to be updated (0 is default for numTicks).
    // If dt = nextTick then 1 tick needs to be updated (and so forth).
    // Note: As we mention in summary, you should keep track of how large numTicks is.
    // If it is large, then either your game was asleep, or the machine cannot keep up.
    if (gameTime > nextTick) {
      // var timeSinceTick = Math.min(gameTime - this.lastTick, 1000);
      var timeSinceTick = gameTime - this.lastTick;
      const numTicks = Math.min(Math.floor(timeSinceTick / this.tickLength), this.maxTicks);
      console.log("timesince", { timeSinceTick, numTicks });
      
      this.queueUpdates(numTicks);
    }

    const dt = gameTime - this.lastRender;

    this.renderFn(dt, gameTime);

    this.lastRender = gameTime;

    if (this._requestStop) {
      this._isRunning = false;
      this.startTime = 0;
      this.lastRender = 0;
      this.lastTick = 0;
      this._requestStop = false;
      this._emitter.emit({});
    } else {
      window.requestAnimationFrame(this.tick);
    }
  }

  queueUpdates(numTicks: number) {
    for (var i = 0; i < numTicks; i++) {
      this.lastTick = this.lastTick + this.tickLength; // Now lastTick is this tick. THIS IS WHY ITS BEHIND 
      console.log("update", { i, lastTick: this.lastTick });
      this.updateFn(this.tickLength, this.lastTick);
    }
  }
}