import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { StoreContext } from 'redux-react-hook';
import { store } from './store';
import { Engine } from './ecs/engine';
import { BaseSystem, ISystem } from './ecs';
import { start } from './world';

ReactDOM.render(
  <StoreContext.Provider value={store}>
    <App />
  </StoreContext.Provider>,
document.getElementById('root'));

serviceWorker.unregister();


// start();

// (window as any).ecs = ecs;