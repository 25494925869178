export interface Disposable {
  (): void;
}

export interface EventHandler<T> {
  (e: T): void;
}

export interface EventSource<T> {
  on: (fn: EventHandler<T>) => Disposable;
  off: (fn: EventHandler<T>) => void;
}

export class EventEmitter<T> implements EventSource<T> {
  private subscribers: EventHandler<T>[] = [];

  public on(fn: EventHandler<T>): Disposable {
    this.subscribers.push(fn);
    return () => this.off(fn);
  }

  public off(fn: EventHandler<T>) {
    var idx = this.subscribers.indexOf(fn);
    if (idx > -1) {
      this.subscribers.splice(idx, 1);
    }
  }

  public emit(e: T) {
    for (const handler of this.subscribers) {
      handler(e);
    }
  }

  public get hasSubscribers() {
    return this.count > 0;
  }

  public get count() {
    return this.subscribers.length;
  }
}
