import * as React from "react";
import { Layout, Point, Orientation, Hex } from "../hexgrid/hexlib";

export interface MapContext {
  layout: Layout,
  points: string,
  padding: number;
  sizeX: number;
  sizeY: number;
}

export function makeMapContext(sizeX: number, sizeY: number, orientation: Orientation): MapContext {
  const layout = new Layout(Layout.pointy, new Point(sizeX, sizeY), new Point(0, 0));
  const points = layout.polygonCorners(new Hex(0, 0, 0)).map(p => `${p.x},${p.y}`).join(" ");
  return {
    sizeX,
    sizeY,
    layout,
    points,
    padding: 30
  };
}

export const mapContext = React.createContext<MapContext>(makeMapContext(52, 52, Layout.pointy));