import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
// import { Grid } from './hexgrid/Grid';
import Status from './Status';
import TileMap from './components/TileMap';


class App extends Component {
  render() {
    return (
      <div id="container" style={{display:"flex", justifyContent:"stretch", alignItems:"stretch"}} onKeyPress={_ => console.log("hello")}>
        <div id="stage" style={{backgroundColor:"#ccc"}}>
          <TileMap />
        </div>
        <div id="sidebar" style={{ width: "200px" }}>
          <Status />
          this is a sidebar
        </div>
      </div>
    );
  }
}

export default App;
