import { createAction } from "typesafe-actions";
import { Hex } from "../hexgrid/hexlib";

export const tick = createAction("@@ftp/tick");

export const generateWorld = createAction("@@ftp/generateWorld", resolve => {
  return (size: number) => resolve({ size });
});

export const changeRandomTile = createAction("@@ftp/changeRandomTile");

export const buildFarm = createAction("@@ftp/buildFarm", resolve => {
  return (hex: Hex) => resolve({ hex });
});