import React from "react";
import desert from "./assets/terrain-desert-01.svg";
import grass from "./assets/terrain-grass-03.svg";
import mountain from "./assets/terrain-mountain-01.svg";
import water from "./assets/terrain-water-01.svg";
import concrete from "./assets/terrain-concrete-01.svg";
import farm from "./assets/object-farm-01.svg";
import colony from "./assets/object-colony-01.svg";

interface Props {
  height: number;
  width: number;
}

const assets = {
  "terrain-desert": desert,
  "terrain-grass": grass,
  "terrain-mountain": mountain,
  "terrain-water": water,
  "terrain-concrete": concrete,
  "object-farm": farm,
  "object-colony": colony,
};

export const Assets: React.SFC<Props> = ({ height, width }) => {
  const viewBox = `0 0 ${width} ${height}`;

  return (
    <defs>
      {Object.entries(assets).map(([id, href]) => (
        <pattern key={id} id={id} width="100%" height="100%" viewBox={viewBox}>
          <image xlinkHref={href} height={height} width={width} />
        </pattern>
      ))}
    </defs>
  )
};