import _ from "lodash";

export class Point {
  constructor(public readonly x: number, public readonly y: number) { }
}

export class Rect {
  constructor(public readonly x: number, public readonly y: number, public readonly height: number, public readonly width: number) { }
}

export class Hex {
  constructor(public readonly q: number, public readonly r: number, public readonly s: number) {
    if (Math.round(q + r + s) !== 0) throw "q + r + s must be 0";
  }

  public add(b: Hex): Hex {
    return new Hex(this.q + b.q, this.r + b.r, this.s + b.s);
  }

  public subtract(b: Hex): Hex {
    return new Hex(this.q - b.q, this.r - b.r, this.s - b.s);
  }

  public scale(k: number): Hex {
    return new Hex(this.q * k, this.r * k, this.s * k);
  }

  public rotateLeft(): Hex {
    return new Hex(-this.s, -this.q, -this.r);
  }

  public rotateRight(): Hex {
    return new Hex(-this.r, -this.s, -this.q);
  }

  public static directions: Hex[] = [new Hex(1, 0, -1), new Hex(1, -1, 0), new Hex(0, -1, 1), new Hex(-1, 0, 1), new Hex(-1, 1, 0), new Hex(0, 1, -1)];

  public static direction(direction: number): Hex {
    return Hex.directions[direction];
  }

  public neighbor(direction: number): Hex {
    return this.add(Hex.direction(direction));
  }

  public static diagonals: Hex[] = [new Hex(2, -1, -1), new Hex(1, -2, 1), new Hex(-1, -1, 2), new Hex(-2, 1, 1), new Hex(-1, 2, -1), new Hex(1, 1, -2)];

  public diagonalNeighbor(direction: number): Hex {
    return this.add(Hex.diagonals[direction]);
  }

  public len(): number {
    return (Math.abs(this.q) + Math.abs(this.r) + Math.abs(this.s)) / 2;
  }

  public distance(b: Hex): number {
    return this.subtract(b).len();
  }

  public round(): Hex {
    var qi: number = Math.round(this.q);
    var ri: number = Math.round(this.r);
    var si: number = Math.round(this.s);
    var q_diff: number = Math.abs(qi - this.q);
    var r_diff: number = Math.abs(ri - this.r);
    var s_diff: number = Math.abs(si - this.s);
    if (q_diff > r_diff && q_diff > s_diff) {
      qi = -ri - si;
    }
    else
      if (r_diff > s_diff) {
        ri = -qi - si;
      }
      else {
        si = -qi - ri;
      }
    return new Hex(qi, ri, si);
  }

  public lerp(b: Hex, t: number): Hex {
    return new Hex(this.q * (1.0 - t) + b.q * t, this.r * (1.0 - t) + b.r * t, this.s * (1.0 - t) + b.s * t);
  }

  public linedraw(b: Hex): Hex[] {
    var N: number = this.distance(b);
    var a_nudge: Hex = new Hex(this.q + 0.000001, this.r + 0.000001, this.s - 0.000002);
    var b_nudge: Hex = new Hex(b.q + 0.000001, b.r + 0.000001, b.s - 0.000002);
    var results: Hex[] = [];
    var step: number = 1.0 / Math.max(N, 1);
    for (var i = 0; i <= N; i++) {
      results.push(a_nudge.lerp(b_nudge, step * i).round());
    }
    return results;
  }

  public toString() {
    return `${this.q},${this.r},${this.s}`;
  }
}


// export class OffsetCoord {
//   constructor(public col: number, public row: number) { }
//   public static EVEN: number = 1;
//   public static ODD: number = -1;

//   public static qoffsetFromCube(offset: number, h: Hex): OffsetCoord {
//     var col: number = h.q;
//     var row: number = h.r + (h.q + offset * (h.q & 1)) / 2;
//     return new OffsetCoord(col, row);
//   }


//   public static qoffsetToCube(offset: number, h: OffsetCoord): Hex {
//     var q: number = h.col;
//     var r: number = h.row - (h.col + offset * (h.col & 1)) / 2;
//     var s: number = -q - r;
//     return new Hex(q, r, s);
//   }


//   public static roffsetFromCube(offset: number, h: Hex): OffsetCoord {
//     var col: number = h.q + (h.r + offset * (h.r & 1)) / 2;
//     var row: number = h.r;
//     return new OffsetCoord(col, row);
//   }


//   public static roffsetToCube(offset: number, h: OffsetCoord): Hex {
//     var q: number = h.col - (h.row + offset * (h.row & 1)) / 2;
//     var r: number = h.row;
//     var s: number = -q - r;
//     return new Hex(q, r, s);
//   }

// }

// export class DoubledCoord {
//   constructor(public col: number, public row: number) { }

//   public static qdoubledFromCube(h: Hex): DoubledCoord {
//     var col: number = h.q;
//     var row: number = 2 * h.r + h.q;
//     return new DoubledCoord(col, row);
//   }


//   public qdoubledToCube(): Hex {
//     var q: number = this.col;
//     var r: number = (this.row - this.col) / 2;
//     var s: number = -q - r;
//     return new Hex(q, r, s);
//   }


//   public static rdoubledFromCube(h: Hex): DoubledCoord {
//     var col: number = 2 * h.q + h.r;
//     var row: number = h.r;
//     return new DoubledCoord(col, row);
//   }


//   public rdoubledToCube(): Hex {
//     var q: number = (this.col - this.row) / 2;
//     var r: number = this.row;
//     var s: number = -q - r;
//     return new Hex(q, r, s);
//   }

// }

export class Orientation {
  constructor(
    // forward matrix
    public readonly f0: number,
    public readonly f1: number,
    public readonly f2: number,
    public readonly f3: number,
    // inverse matrix
    public readonly b0: number,
    public readonly b1: number,
    public readonly b2: number,
    public readonly b3: number,

    // size
    public readonly widthMultiplier: number,
    public readonly heightMultiplier: number,

    public readonly startAngle: number
  ) { }
}

export class Layout {
  constructor(public orientation: Orientation, public size: Point, public origin: Point) { }

  public static pointy: Orientation = new Orientation(
    Math.sqrt(3.0), Math.sqrt(3.0) / 2.0, 0.0, 3.0 / 2.0,
    Math.sqrt(3.0) / 3.0, -1.0 / 3.0, 0.0, 2.0 / 3.0,
    Math.sqrt(3.0), 2,
    0.5);
  public static flat: Orientation = new Orientation(
    3.0 / 2.0, 0.0, Math.sqrt(3.0) / 2.0, Math.sqrt(3.0),
    2.0 / 3.0, 0.0, -1.0 / 3.0, Math.sqrt(3.0) / 3.0,
    2, Math.sqrt(3.0),
    0.0);

  public hexToPixel(h: Hex): Point {
    const { orientation, size, origin } = this;
    const x = (orientation.f0 * h.q + orientation.f1 * h.r) * size.x;
    const y = (orientation.f2 * h.q + orientation.f3 * h.r) * size.y;
    return new Point(x + origin.x, y + origin.y);
  }

  public pixelToHex(p: Point): Hex {
    const { orientation, size, origin } = this;
    const pt = new Point((p.x - origin.x) / size.x, (p.y - origin.y) / size.y);
    const q = orientation.b0 * pt.x + orientation.b1 * pt.y;
    const r = orientation.b2 * pt.x + orientation.b3 * pt.y;
    return new Hex(q, r, -q - r);
  }

  public hexCornerOffset(corner: number): Point {
    const { orientation, size } = this;
    const angle = 2.0 * Math.PI * (orientation.startAngle - corner) / 6.0;
    return new Point(size.x * Math.cos(angle), size.y * Math.sin(angle));
  }

  public polygonCorners(h: Hex): Point[] {
    const corners = [];
    const center = this.hexToPixel(h);
    for (let i = 0; i < 6; i++) {
      const offset = this.hexCornerOffset(i);
      corners.push(new Point(center.x + offset.x, center.y + offset.y));
    }
    return corners;
  }

  public hexSize(): Point {
    return new Point(this.orientation.widthMultiplier * this.size.x, this.orientation.heightMultiplier * this.size.y);
  }

  public dimensions(hexes: Hex[]): Rect {
    const points = hexes.map(h => this.hexToPixel(h));
    const xPoints = points.map(p => p.x).sort((a, b) => a - b);
    const yPoints = points.map(p => p.y).sort((a, b) => a - b);
    const size = this.hexSize();

    return {
      x: xPoints[0] - size.x / 2,
      y: yPoints[0] - size.y / 2,
      height: Math.abs(yPoints[0] - size.y / 2) + Math.abs(yPoints[yPoints.length - 1] + size.y / 2),
      width: Math.abs(xPoints[0] - size.x / 2) + Math.abs(xPoints[xPoints.length - 1] + size.x / 2),
    };
  }
}
