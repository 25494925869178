import React, { useCallback } from "react";
import { State, Dispatch } from "./store";
// import { connect } from "react-redux";
import { generateWorld } from "./store/actions";
import { useMappedState, useDispatch } from "redux-react-hook";
import { useGame } from "./hooks/useGame";

interface Props {
  // tickNumber: number;
  // dispatch: Dispatch;
}

const Status: React.SFC<Props> = (props) => {
  const mapState = useCallback((state: State) => ({ tickNumber: state.tickNumber }), []);
  const dispatch = useDispatch();

  const [isRunning, loopControls] = useGame();

  const generate = useCallback(() => {
    dispatch(generateWorld(3))
  }, []);
  
  const { tickNumber } = useMappedState(mapState);

  return (
    <>
      <div>
        <button onClick={_ => isRunning ? loopControls.stop() : loopControls.start()}>{isRunning ? "Stop" : "Start"}</button>
      </div>
      <div>
        Tick: {tickNumber}
        <button onClick={_ => generate()}>Regenerate</button>
      </div>
    </>
  );
}

export default Status;